// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---frontend-components-development-logo-page-tsx": () => import("../frontend/components/development/logo-page.tsx" /* webpackChunkName: "component---frontend-components-development-logo-page-tsx" */),
  "component---frontend-components-main-tsx": () => import("../frontend/components/main.tsx" /* webpackChunkName: "component---frontend-components-main-tsx" */),
  "component---frontend-pages-index-tsx": () => import("../frontend/pages/index.tsx" /* webpackChunkName: "component---frontend-pages-index-tsx" */),
  "component---frontend-pages-endpoint-tsx": () => import("../frontend/pages/endpoint.tsx" /* webpackChunkName: "component---frontend-pages-endpoint-tsx" */)
}

